import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Accordion from '../components/Accordion';
import PerfectScrollbar from 'react-perfect-scrollbar';

import style from '../assets/style/pages/career.module.scss';

export default () => (
  <Layout>
    <SEO title="Kariera" />

    <section className={style.careerSection}>
      <div className="container">
        <div className={`row ${style.careerSection__row}`}>

          <div className="col-12 col-lg-6 offset-xl-1 col-xl-5">
            <h1>Kariera</h1>
            <PerfectScrollbar className={style.scrollArea1} horizontal={false}>
              <p>
                BPD jest pracownią projektową założoną w 1998 r., zapewniającą kompleksową obsługę projektową w zakresie dróg, ulic i wewnętrznych układów komunikacyjnych na terenie Miasta Krakowa i województwa małopolskiego. Pracownia specjalizuje się projektowaniu ścieżek rowerowych i jest liderem w ich opracowaniu.
              </p>
            </PerfectScrollbar>
          </div>

          <div className={`col-12 col-lg-6 offset-xxl-1 col-xxl-5 ${style.jobOffersColumn}`}>
            <PerfectScrollbar className={style.scrollArea2} horizontal={false}>
              <Accordion title="Asystent projektanta branży drogowej">
                <strong>Osoba na tym stanowisku będzie odpowiedzialna za:</strong>
                <ul>
                  <li>opracowanie dokumentacji projektowej z branży drogowej,</li>
                  <li>uzgadnianie rozwiązań z projektantem,</li>
                  <li>koordynację uzgodnień dokumentacji w podległych urzędach.</li>
                </ul>
                <strong>Wymagania:</strong>
                <ul>
                  <li>ukończone studia inżynierskie w specjalności drogowej lub pokrewne,</li>
                  <li>bardzo dobra znajomość oprogramowania: AutoCad i Ms Office,</li>
                  <li>zdolności organizacyjne i umiejętność pracy w zespole</li>
                  <li>mile widziane doświadczenie w pracy przy sporządzaniu projektów drogowych</li>
                </ul>
                <strong>Oferujemy:</strong>
                <ul>
                  <li>wynagrodzenie odpowiadające posiadanym kwalifikacjom,</li>
                  <li>umowę o pracę na pełny etat,</li>
                  <li>udział w ciekawych projektach,</li>
                  <li>możliwość odbycia niezbędnej praktyki celem uzyskania uprawnień budowlanych.</li>
                </ul>
                <p>
                  Zainteresowanych prosimy o nadsyłanie ofert zawierających CV i list motywacyjny z dopiskiem <strong>PRACA – ASYSTENT PROJEKTANTA BRANŻY DROGOWEJ</strong> na adres mailowy: <a href="mailto:bpd@bpd-krakow.pl">bpd@bpd-krakow.pl</a>
                </p>
              </Accordion>
              <Accordion title="Projektant branży drogowej">
                <strong>Osoba na tym stanowisku będzie odpowiedzialna za:</strong>
                <ul>
                  <li>samodzielne wykonywanie projektów branży drogowej;</li>
                  <li>pełnienie nadzoru nad realizacją projektu</li>
                  <li>udział i koordynacja w procesie formalno-prawnym projektu;</li>
                  <li>reprezentowanie biura w kontaktach z klientami i innymi uczestnikami procesu inwestycyjnego;</li>
                  <li>wykonywanie prac projektowych zgodnie z obowiązującymi przepisami, zasadami wiedzy inżynierskiej</li>
                  <li>koordynację uzgodnień dokumentacji</li>
                </ul>
                <strong>Wymagania:</strong>
                <ul>
                  <li>doświadczenie w pracy przy wykonywaniu projektów branży drogowej;</li>
                  <li>biegła znajomość oprogramowania komputerowego: AutoCAD , Ms Office;</li>
                  <li>znajomość procesu inwestycyjnego i przepisów prawa budowlanego;</li>
                  <li>umiejętność pracy w zespole;</li>
                  <li>uprawnienia mile widziane</li>
                </ul>
                <strong>Oferujemy:</strong>
                <ul>
                  <li>wynagrodzenie odpowiadające posiadanym kwalifikacjom,</li>
                  <li>umowę o pracę na pełny etat,</li>
                  <li>udział w ciekawych projektach</li>
                  <li>dla osób nie posiadających uprawnień możliwość odbycia niezbędnej praktyki celem uzyskania uprawnień budowlanych</li>
                </ul>
                <p>
                  Zainteresowanych prosimy o nadsyłanie ofert zawierających CV i list motywacyjny z dopiskiem <strong>PRACA – PROJEKTANT BRANŻY DROGOWEJ</strong> na adres mailowy: <a href="mailto:bpd@bpd-krakow.pl">bpd@bpd-krakow.pl</a>
                </p>
              </Accordion>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
