import React, { useRef } from 'react';
import { StyledAccordion } from './Accordion.styled';

const Accordion = ({title, children}) => {
  const [open, setOpen] = React.useState(false);
  const [bodyHeight, setBodyHeight] = React.useState(0);

  const body = useRef();
  const content = useRef();

  const toggleAccordion = () => {
    const height = content.current.offsetHeight;
    if (open) {
      setOpen(false);
      setBodyHeight(0)
    } else {
      setOpen(true);
      setBodyHeight(height)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      toggleAccordion();
    }
  }

  return (
    <StyledAccordion open={open} bodyHeight={bodyHeight}>
      <div className="accordion-header" onClick={toggleAccordion} onKeyPress={handleKeyPress} role="button" tabIndex="0">
        <h2>{title}</h2>
        <span className="arrow"></span>
      </div>
      <div ref={body} className="accordion-body">
        <div ref={content}>
          {children}
        </div>
      </div>
    </StyledAccordion>
  )
};

export default Accordion;
