import styled from '@emotion/styled';

export const StyledAccordion = styled.div`
  border: 2px solid #c8cacc;
  margin: 0 0 15px;

  @media (min-width: 768px) {
    margin: 0 0 30px;
  }

  .accordion-header {
    position: relative;
    padding: 20px;
    padding-right: 70px;
    cursor: pointer;

    &:focus,
    &:hover {
      outline: none;

      h2 {
        color: rgba(0, 0, 0, .5);
      }
    }

    h2 {
      margin: 0;
      font-size: 1rem;
      transition: color .3s;
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      border: 2px solid #a92d37;
      border-radius: 50%;
      transform: ${({ open }) => open ? 'rotate(-180deg)' : 'none'};
      transition: transform .5s;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 2px);
        left: 50%;
        width: 10px;
        height: 10px;
        border: 2px solid #222222;
        border-top: none;
        border-left: none;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    @media (min-width: 576px) {
      padding: 30px;
      padding-right: 80px;

      h2 {
        font-size: 1.125rem;
      }

      .arrow {
        right: 30px;
      }
    }
  }

  .accordion-body {
    height: ${({ bodyHeight }) => `${bodyHeight}px`};
    overflow: hidden;
    transition: height .5s ${({ open }) => open ? 'ease-in' : 'ease-out'};

    > div {
      padding: 20px;
      font-size: .875rem;

      strong {
        display: block;
        margin: 0 0 1rem;
      }

      ul {
        margin: 0 0 1rem;
        list-style: none;

        li {
          position: relative;
          margin: 0 0 .25rem;
          padding-left: 20px;

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 7px;
            left: 0;
            width: 12px;
            height: 12px;
            background: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjYTkyZDM3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjAuMjg1IDJsLTExLjI4NSAxMS41NjctNS4yODYtNS4wMTEtMy43MTQgMy43MTYgOSA4LjcyOCAxNS0xNS4yODV6Ii8+PC9zdmc+") no-repeat center / contain;
          }
        }
      }

      p {
        margin: 0;
        text-align: left;
        color: inherit;

        strong {
          display: inline;
          margin: 0;
        }
      }

      a {
        color: inherit;
        opacity: .5;
        text-decoration: none;
        white-space: nowrap;
      }
    }

    @media (min-width: 576px) {
      > div {
        padding: 30px;
        font-size: 1rem;
      }
    }
  }
`;
